<template>
  <div class="user-create-page">
    <h2>Create User</h2>

    <user-form
      v-model="form"
      class="my-3"
    />

    <b-button @click="submit">Save</b-button>
  </div>
</template>

<script>
import UserForm from '../components/UserForm.vue'

export default {
  name: 'UserCreate',

  components: {
    UserForm
  },

  data () {
    return {
      form: null
    }
  },

  methods: {
    validate () {
      let valid = true

      return valid
    },

    async submit () {
      if (!this.validate()) {
        console.warn('Invalid form')
        return
      }

      try {
        const resp = await this.$users.create(this.form)
        const user = resp.data.data
        this.$router.push({
          name: 'user-edit',
          params: {
            id: user.id
          }
        })
      }
      catch (e) {
        console.error(e.message)
      }
    }
  }
}
</script>

<style lang="scss">
</style>
