<template>
  <b-card class="user-form">
    <b-form-group>
      <label>Name</label>
      <b-form-input
        v-model="internalValue.name"
      />
    </b-form-group>

    <b-form-group>
      <label>Email</label>
      <b-form-input
        v-model="internalValue.email"
        type="email"
      />
    </b-form-group>

    <b-form-group>
      <label>Password</label>
      <b-form-input
        v-model="internalValue.password"
        type="password"
      />
    </b-form-group>

    <b-form-group>
      <label>Confirm Password</label>
      <b-form-input
        v-model="internalValue.password_confirmation"
        type="password"
      />
    </b-form-group>

    <b-form-group>
      <b-form-checkbox
        v-model="internalValue.status"
        switch
      >
        Active
      </b-form-checkbox>
    </b-form-group>
  </b-card>
</template>

<script>
export default {
  name: 'UserForm',

  props: {
    value: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      internalValue: {
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
        status: true
      }
    }
  },

  created () {
    if (this.value) {
      this.internalValue = JSON.parse(JSON.stringify(this.value))
    }
    this.emitInput()
  },

  methods: {
    emitInput () {
      this.$emit('input', this.internalValue)
    }
  }
}
</script>

<style>

</style>